import React, { CSSProperties, useState } from 'react'

import './Episode.scss'

import youtubeLogo from '../../assets/images/youtube-logo.png'
import soundCloudLogo from '../../assets/images/soundcloud-logo.png'
import podCloudLogo from '../../assets/images/podcloud-logo.png'
import classNames from 'classnames'

interface IEpisode {
    episodeNumber: string
    date?: string
    title?: string
    summary?: string
    versions?: IVersion[],
    writingProgress?: number
    productionProgress?: number
}

interface IVersion {
    date?: string
    fileName?: string
    youtube?: string
    soundCloud?: string
    podCloud?: string
}

interface IEpisodeProps {
    episode: IEpisode
}

function Episode(props: IEpisodeProps) {
    const { episode } = props
    const {
        episodeNumber,
        date,
        title,
        summary,
        versions,
        writingProgress,
        productionProgress,
    } = episode

    const areFilesAvailable = !!versions && !!versions[0].fileName
    const isStreamingAvailable = !!versions && !!versions[0].youtube && !!versions[0].soundCloud && !!versions[0].podCloud

    const [versionIndex, setVersionIndex] = useState(!!versions ? versions!.length - 1 : 0)

    const progressStyle = (progress: number): CSSProperties => ({
        background: `linear-gradient(to right, white, white ${progress}%, black ${progress}%)`,
    })

    const progressTextStyle = (progress: number): CSSProperties => ({
        background: `linear-gradient(to right, black, black ${progress}%, white ${progress}%)`,
        backgroundClip: 'text',
    })

    const versionClassName = (index: number) => classNames(
        'version',
        versionIndex === index && 'selected-version'
    )

    const warningClassName = () => classNames(
        'warning',
        !!versions && versionIndex === versions.length - 1 && 'warning-collapsed'
    )

    const dateElement = () => (<span className="date">{dateString() ? ' - ' : ''}{dateString() ?? ''}</span>)

    const handleVersionClick = (index: number) => {
        setVersionIndex(index)
    }

    const dateString = () => {
        if (!versions && !!date) {
            return date
        } else {
            return versions![versionIndex].date
        }
    }

    return (
        <div className="episode row col-sm-12">
            <div className="description col-md-10">
                <div className="col-xs-12 align info">
                    <div className="label-line">
                        <div className="label">Épisode {episodeNumber}{dateElement()}</div>
                        {!!versions && versions!.length > 1 && versions!.map((_, index) => {
                            return <div className={versionClassName(index)} onClick={() => handleVersionClick(index)}>v{index + 1}</div>
                        })}
                    </div>
                    {title && <div className="title">
                        {title}
                        {!!versions && versions!.length > 1 &&
                            <span className="title-version"> - v{versionIndex + 1}</span>
                        }
                    </div>}
                    {summary && <div className="summary">{summary}</div>}
                    {!!versions &&
                        <div className={warningClassName()}>
                            <div>
                                Attention! Vous êtes sur le point de télécharger la première version de cet épisode. Celle-ci est disponible pour
                                référence, mais sa qualité audio n'est pas optimale.
                            </div>
                        </div>
                    }
                </div>
                {isStreamingAvailable && (
                    <div>
                        <a href={versions![versionIndex].youtube} target="_blank">
                            <img src={youtubeLogo} alt="Youtube Logo" />
                        </a>
                        <a href={versions![versionIndex].soundCloud} target="_blank">
                            <img src={soundCloudLogo} alt="SoundCloud Logo" />
                        </a>
                        <a href={versions![versionIndex].podCloud} target="_blank">
                            <img src={podCloudLogo} alt="PodCloud Logo" />
                        </a>
                    </div>
                )}
            </div>
            {areFilesAvailable && (
                <div className="files col-md-2 col-sm-12">
                    <a href={`/audio/mp3/${versions![versionIndex].fileName}.mp3`} download>
                        MP3 320kps
                    </a>
                    <a href={`/audio/cd/${versions![versionIndex].fileName}.flac`} download>
                        Qualité CD
                    </a>
                    <a href={`/audio/hires/${versions![versionIndex].fileName}.flac`} download>
                        Hi-Res
                    </a>
                </div>
            )}
            {!areFilesAvailable && (
                <div className="episode-progress col-md-10">
                    <div className="writing">
                        <div>Écriture</div>
                        <div className="progress-bar" style={progressStyle(writingProgress!)}>
                            <div style={progressTextStyle(writingProgress!)}>{writingProgress}%</div>
                        </div>
                    </div>
                    <div className="production">
                        <div>Enregistrement / Mixage</div>
                        <div className="progress-bar" style={progressStyle(productionProgress!)}>
                            <div style={progressTextStyle(productionProgress!)}>{productionProgress}%</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Episode
