import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './pages/App/App'
import * as serviceWorker from './serviceWorker'

declare global {
    interface Window {
        matomo: any
    }

    interface Matomo {
        isUserOptedOut: Function
    }
}

window.matomo = window.matomo || {}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
