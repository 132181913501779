export default [
    {
        episodeNumber: '1',
        title: 'Le Départ',
        summary: `Jean-Valriel (dit Jivé) s'embarque dans une aventure dont il ignore encore tout. 
        Il va rapidement croiser la route de Tarak, qui l'accompagnera certainement pour un moment. 
        Mais les premiers problèmes surviennent pour nos deux amis...`,
        versions: [
            {
                date: '13-06-2020',
                fileName: 'la-quete-de-norulmyr_01_le-depart',
                youtube: 'https://www.youtube.com/watch?v=dT6dKHI0bnI&t=63s',
                soundCloud: 'https://soundcloud.com/morphet-productions/episode-1-le-depart',
                podCloud: 'https://podcloud.fr/podcast/la-quete-de-norulmyr',
            },
            {
                date: '14-10-2021',
                fileName: 'la-quete-de-norulmyr_01_le-depart_v2',
                youtube: 'https://youtu.be/mMFTcIUn5Kg',
                soundCloud: 'https://soundcloud.com/morphet-productions/episode-1-le-depart-1',
                podCloud: 'https://pdca.st/ntfx',
            }
        ]
    },
    {
        episodeNumber: '2',
        title: `Val'Rador`,
        summary: `Jivé et Tarak s'arrêtent sur Val'Rador, une ancienne station de minage naine reconvertie en relais spatial.`,
        versions: [
            {
                date: '26-09-2020',
                fileName: 'la-quete-de-norulmyr_02_val-rador',
                youtube: 'https://youtu.be/HfhOwRrl86k',
                soundCloud: 'https://soundcloud.com/morphet-productions/episode-2-valrador',
                podCloud: 'https://pdca.st/6t3b',
            }
        ]
    },
    {
        episodeNumber: '3',
        title: `Les Zorax`,
        summary: `Jivé, Tarak et Veldem sont piégés dans un croiseur Zorax et vont tout faire pour ne pas finir en repas pour l'équipage...`,
        versions: [
            {
                date: '12-05-2021',
                fileName: 'la-quete-de-norulmyr_03_les-zorax',
                youtube: 'https://youtu.be/63tnBzZA_Nc',
                soundCloud: 'https://soundcloud.com/morphet-productions/episode-3-les-zorax',
                podCloud: 'https://pdca.st/IcbB',
            }
        ]
    },
    {
        episodeNumber: '4',
        date: 'Mis à jour le 13-08-2023',
        title: 'Telmak',
        summary: `L'équipe arrive sur Telmak, la planète sur laquelle Jivé et Veldem doivent se rendre. La rune de Rolnor, qui doit les mener à Norulmyr, se trouve là, dans une construction ancienne et abandonnée.`,
        writingProgress: 80,
        productionProgress: 0,
    }
]
