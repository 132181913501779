import React, { CSSProperties } from 'react'

import './Channel.scss'

interface IChannel {
    pictureUrl: string
    link: string
    blank?: boolean
}

function Episode(props: IChannel) {
    const { pictureUrl, link, blank } = props

    const handleChannelClick = () => {
        window.open(link, blank ? '_blank' : '_self')
    }

    return (
        <div className="channel col-6 col-md-2">
            <img src={pictureUrl} onClick={handleChannelClick} />
        </div>
    )
}

export default Episode
