import React from 'react'

import { TwitterFollowButton, } from 'react-twitter-embed';

import Episode from '../../components/Episode/Episode'
import Channel from '../../components/Channel/Channel'
import episodesList from '../../assets/episodes-list'

import twitterLogo from '../../assets/images/twitter-logo.png'
import youtubeLogo from '../../assets/images/youtube-logo-color.png'
import discordLogo from '../../assets/images/discord-logo.png'
import emailLogo from '../../assets/images/e-mail-logo.png'

import './Download.scss'

function Download() {
    return (
        <div id="download-page" className="justify-content-center">
            <div className="introduction col-sd-12">
                <p>
                    La Quête de Norulmyr, c'est une aventure initiée par un grand gamin motivé par des créations formidables comme Le Donjon de 
                    Naheulbeuk, Reflets d'Acide, La Légende de Xantha ou encore Milhana. Je me suis décidé à la faveur du confinement à me
                    lancer dans l'aventure et vous propose cette nouvelle saga spatio-heroïquo-fantaisie.
                </p>
                <p>
                    Les versions Hi-Res des épisodes sont beaucoup plus grosses que les autres et vous ne devriez les utiliser que si vous
                    êtes vraiment sensibles des oreilles. Seul l'épisode 3 a été enregistré avec du matériel qui pourrait vous permettre de sentir 
                    la différence.
                </p>
                <p>
                    Pour la réalisation de cette série j'utilise principalement des effets sonores issus de{' '}
                    <a href="https://www.soundeffectsplus.com" target="_blank">
                        soundeffects+
                    </a>
                    et de packs achetés auprès des créateurs{' '}
                    <a href="https://cafofomusic.com/" target="_blank">
                        Cafofo Music
                    </a>{' '}.
                </p>
                <p>
                    Si vous appréciez la série, vous pouvez me suivre sur Soundcloud, Youtube, Twitter et rejoindre le serveur Discord de Norulmyr (liens ci-dessous).
                </p>
                <p>Bonne écoute !</p>
            </div>
            <div className="channels row justify-content-center col-sd-12">
                <Channel pictureUrl={emailLogo} link="mailto:morphet.contact@gmail.com"/>
                <Channel pictureUrl={discordLogo} link="https://discord.gg/sAe8F2Pygs" blank/>
                <Channel pictureUrl={youtubeLogo} link="https://www.youtube.com/channel/UCig285mbyJYt-raVrpTXtbg" blank/>
                <Channel pictureUrl={twitterLogo} link="https://twitter.com/norulmyr" blank/>
            </div>
            <div>
            </div>
            {episodesList.map((episode) => {
                return <Episode key={`episode-${episode.episodeNumber}`} episode={episode} />
            })}
        </div>
    )
}

export default Download
