import React from 'react'

import Download from '../Download/Download'
import CookieWarning from '../../components/CookieWarning/CookieWarning'
import logo from '../../assets/images/logo.svg'
import banner from '../../assets/images/banner.svg'

import './App.scss'

function App() {
    return (
        <div className="app container">
            <header>
                <img src={logo} alt="Logo Norulmyr.com" />
                <h1>La Quête de Norulmyr</h1>
            </header>
            <div className="page-content">
                <div>
                    <Download />
                </div>
            </div>
            <div className="licence col-md-12">
                <div className="explanations">
                    <div>Les œuvres disponibles sur ce site sont mises à disposition selon les termes de la </div>
                    <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank">
                        Licence Creative Commons Attribution - Pas d&#39;Utilisation Commerciale - Pas de Modification
                        4.0 International
                    </a>
                </div>
                <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank">
                    <img
                        alt="Licence Creative Commons"
                        style={{ borderWidth: 0 }}
                        src="https://i.creativecommons.org/l/by-nc-nd/4.0/88x31.png"
                    />
                </a>
            </div>
            {/* <CookieWarning /> */}
        </div>
    )
}

export default App
